import React from "react";
import {
  getCarreras,
  getMiInfo,
  getMyCurses,
  getMyLaboralSituation,
} from "../_redux/UsuariosCrud";
import { actions } from "../../../modules/Auth/_redux/authRedux";
import { useDispatch } from "react-redux";

export const useFetchGetCarreras = (
  pFetch,
  uId,
  setError,
  setLoadingInstances
) => {
  const [comboCarreras, setComboCarreras] = React.useState({});
  const dispatch = useDispatch();

  React.useState(() => {
    const fetch = async (setError, setLoadingInstances) => {
      try {
        const {
          data: { instancias },
        } = await getCarreras();
        if (instancias != null) {
          setComboCarreras(instancias);
          setLoadingInstances(false);
        }
      } catch (error) {
        setLoadingInstances(false);
        setError(true);
      }
    };

    const fetchDocs = async (pFetch, uId, setError) => {
      try {
        const {
          data: { persona },
        } = await getMiInfo(uId);
        dispatch(actions.setPersona(persona));
      } catch (error) {
        setError(true);
      }
    };

    if (pFetch?.files?.length > 0) {
      fetch(setError, setLoadingInstances);
    } else {
      fetchDocs(pFetch, uId, setError);
      fetch(setError, setLoadingInstances);
    }
  }, []);
  return { comboCarreras };
};

export const useFetchDocumentos = (persona, setLoading) => {
  const [files, setFiles] = React.useState();

  React.useEffect(() => {
    if (!persona.files && persona.files.length <= 0) {
      fetch(persona);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [persona]);

  setLoading(true);
  return { files };
};

export const useFetchPersonCurses = (persona, setLoading) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    setLoading(true);

    const fetching = async () => {
      try {
        getMyCurses(persona.id).then(({ data: { inscriptos } }) => {
          getMyLaboralSituation(persona.id).then(({ data: { situacion } }) => {
            dispatch(actions.setCursos(inscriptos));
            dispatch(actions.setSituacionLaboral(situacion[0]));
            setLoading(false);
          });
        });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    fetching();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
