import React, { useState } from "react";
import SVG from "react-inlinesvg";
import {
  toAbsoluteUrl,
  isExpired,
} from "../../../_metronic/_helpers";

export default function TablaInstancias({
  data,
  className,
  clickModalShow,
  chooseInstance,
  combos,
  showingDeleteInstance,
  showingInscriptosModal,
  showingAsistenciasModal,
  showingNotasModal,
}) {
  const ipp = 5;
  const [currPage, setCurrPage] = useState(1);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [sortColumn, setSortColumn] = React.useState(null);
  const [sortDirection, setSortDirection] = useState("asc");

  const normalizeText = (text) =>
    text.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  
  const filteredItems = data.filter((item) => {
    const descripcion = normalizeText(item.descripcion?.trim() || "");
    const query = normalizeText(searchQuery.trim());
    return descripcion.includes(query);
  });

  const handleSort = (column) => {
    if(sortColumn === column) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    }
    else {
      setSortColumn(column);
      setSortDirection("asc");
    }
  }

  const sortedItems = [...filteredItems].sort((a,b) => {
    if (!sortColumn) return 0;

    const valA =  a[sortColumn] || "";
    const valB = b[sortColumn] || "";

    return sortDirection === "asc"
    ?
    valA.toString().localeCompare(valB.toString())
    :
    valB.toString().localeCompare(valA.toString())
  })
  
  const totalPages = Math.ceil(sortedItems?.length / ipp);

  const nextPage = () => {
    setCurrPage((prevPage) => prevPage + 1);
  };

  const prevPage = () => {
    setCurrPage((ppage) => ppage - 1);
  };

  const goto = (pnum) => {
    setCurrPage(pnum);
  };

  const safeCurrPage = Math.max(1, Math.min(currPage, totalPages));
  const startIdx = (safeCurrPage - 1) * ipp;
  const endIdx = Math.min(startIdx + ipp, filteredItems?.length);

  const currItems = sortedItems?.slice(startIdx, endIdx);

  return (
    <div className={`card card-custom ${className}`}>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <button
            className="btn btn-primary font-weight-bolder font-size-sm"
            onClick={() => clickModalShow(true)}
          >
            Crear nueva instancia
          </button>
        </h3>
        <div className="card-toolbar">
          <label className="form-lable text-muted" htmlFor="">
            Buscar por descripción
          </label>
          <input
            className="form-control"
            type="text"
            placeholder="Ej: Descripcion x"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>

      </div>
      {/* Body */}
      <div className="card-body pt-3 pb-0">
      <div className="row">
          <div className="col-xl-12 col-md-12 col-xs-12 col-sm-12">
            <p className="text-muted"><span className="text-danger">*</span>El formato de fechas en el listado corresponden a Año - Mes - Dia</p>
          </div>
        </div>
        <div className="table-responsive">
          <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
            <thead>
              <tr className="text-left text-uppercase">

                {[
                  { key: "carrera.descripcion", label: "Carrera" },
                  { key: "descripcion", label: "Descripción" },
                  { key: "fecha_publicacion", label: "Fecha Publicación" },
                  { key: "fecha_inicio", label: "Fecha Inicio" },
                  { key: "fecha_fin", label: "Fecha Final" },
                ].map(({ key, label }) => (
                  <th key={key} onClick={() => handleSort(key)} className={sortColumn === key ? "thead-selected" : ""}  style={{ cursor: "pointer", minWidth: "150px" }}>
                    {label} {sortColumn === key ? (sortDirection === "asc" ? "▲" : "▼") : ""}
                  </th>
                ))}
                <th style={{ minWidth: "150px" }}>Estado</th>
                <th style={{ minWidth: "150px" }}>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {currItems.length > 0 ? (
                currItems?.map((item, index) => (
                  <tr key={index}>
                    <td className="pl-0">
                      <span
                        className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        {item?.carrera?.descripcion}
                      </span>
                    </td>
                    <td className="pl-0">
                      <span
                        className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                      >
                        {item?.descripcion}
                      </span>
                    </td>
                    <td className="pl-0">
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {(item?.fecha_publicacion)}
                      </span>
                    </td>
                    <td className="pl-0">
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {(item?.fecha_inicio)}
                      </span>
                    </td>
                    <td className="pl-0">
                      <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                        {(item?.fecha_fin)}
                      </span>
                    </td>
                    <td className="pl-0">
                      {isExpired(item?.fecha_fin) ? (
                        <span className="label label-lg label-light-success label-inline">
                          En Curso
                        </span>
                      ) : (
                        <span className="label label-lg label-light-danger label-inline">
                          Finalizado
                        </span>
                      )}
                    </td>
                    <td className="pl-0">
                      <button
                        className="btn btn-icon btn-light btn-sm mx-3"
                        onClick={() => chooseInstance(item)}
                        title="Editar"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Write.svg"
                            )}
                            title="Editar"
                          ></SVG>
                        </span>
                      </button>
                      <button
                        className="btn btn-icon btn-light btn-sm mx-3"
                        onClick={() => showingDeleteInstance(item)}
                        title="Eliminar"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Trash.svg"
                            )}
                            title="Eliminar"
                          ></SVG>
                        </span>
                      </button>
                      <button
                        className="btn btn-icon btn-light btn-sm mx-3"
                        title="Inscriptos"
                        onClick={() => showingInscriptosModal(true, item)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/User.svg"
                            )}
                            title="Inscriptos"
                          ></SVG>
                        </span>
                      </button>
                      <button
                        className="btn btn-icon btn-light btn-sm mx-3"
                        title="Asistencias"
                        onClick={() => showingAsistenciasModal(true, item)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Navigation/Check.svg"
                            )}
                            title="Asistencias"
                          ></SVG>
                        </span>
                      </button>
                      <button
                        className="btn btn-icon btn-light btn-sm mx-3"
                        title="Notas"
                        onClick={() => showingNotasModal(true, item)}
                      >
                        <span className="svg-icon svg-icon-md svg-icon-primary">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Clipboard-list.svg"
                            )}
                            title="Notas"
                          ></SVG>
                        </span>
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <>
                  <tr>
                  <td colSpan={6}>
                  <p>No hay instancias creadas!</p>
                  <p>Crea la primer instancia para empezar a trabjar</p>
                  </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </div>
        <div className="mb-5">
          <div>
            <button
              className="btn btn-light btn-sm"
              disabled={currPage === 1}
              onClick={prevPage}
            >
              Anterior
            </button>
            {data.length > ipp && (
              <>
              {[...Array(Math.ceil(data.length / ipp)).keys()].map((item, idx) => {
                const startPage = Math.max(1, currPage - 4);
                const endPage = Math.min(
                  Math.ceil(data.length / ipp),
                  startPage + 9
                );
                if (item + 1 >= startPage && item + 1 <= endPage) {
                  return (
                    <button
                      key={idx}
                      className={`btn btn-light btn-sm mx-1 ${
                        currPage === item + 1 ? "active" : ""
                      }`}
                      onClick={() => goto(item + 1)}
                    >
                      {item + 1}
                    </button>
                  );
                } else {
                  return null;
                }
              })}
            </>
            )}
            <button
              className="btn btn-light btn-sm"
              disabled={endIdx >= data.length}
              onClick={nextPage}
            >
              Siguiente
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
