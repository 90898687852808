import React from "react";
import { Modal } from "react-bootstrap";
import ProfilePersonalData from "../General/ProfilePersonalData";
import { setUserStateInscripto } from "../../pages/Backoffice/_redux/reduxCrud";
import SuccessMessage from "../General/SuccessMessage";
import { useDispatch } from "react-redux";
import { updateinscripto } from "../../pages/Backoffice/inscripciones/_redux/inscripcionesReducer";
import EditorComponent from "../General/EditorComponent";

export default function ModalInscripciones({
  show,
  itemSelected,
  addToDataStructure,
  hiddingInscripcion,
  editInDataStructure,
}) {
  const [estado, setEstado] = React.useState(
    itemSelected?.estado_id ? itemSelected?.estado_id : "1"
  );
  const [loading, setLoading] = React.useState(false);
  const [suc, setSucc] = React.useState(false);
  const [rejectInput, setRejectInput] = React.useState(
    itemSelected?.observacion ? true : false
  );
  const [motivoRechazo, setMotivoRechazo] = React.useState(
    itemSelected?.observacion ? itemSelected?.observacion : ""
  );
  const [errorReject, setErrorReject] = React.useState("");
  const dispatch = useDispatch();

  const onChangeEstado = (e) => {
    e.preventDefault();
    setEstado(e.target.value);
    if (e.target.value === "3") {
      setRejectInput(true);
    } else {
      setRejectInput(false);
    }
  };

  const handleStatusSubmit = async () => {
    setLoading(true);

    if (estado === "3") {
      if (motivoRechazo === "") {
        setErrorReject("Por favor, proporcione un motivo de rechazo.");
        setLoading(false);
        return;
      }
    } else if (estado === "1") {
      setErrorReject("Por favor, seleccione un estado.");
      setLoading(false);
      return;
    }
    else if (estado == "-1"){
      setErrorReject("Por favor, seleccione un estado");
      setLoading(false);
      return;
    }

    try {
      const res = await setUserStateInscripto(
        itemSelected.id,
        estado,
        motivoRechazo
      );
      if (res) {
        setLoading(false);
        setSucc(true);

        const updItem = {
          ...itemSelected,
          estado_id: parseInt(estado),
          observacion: motivoRechazo,
        };
        dispatch(updateinscripto(updItem));
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleTinyEditor = (e) => {
    setMotivoRechazo(e);
  };

  const quitModal = () => {
    setLoading(false);
    setSucc(false);
    hiddingInscripcion(false);
  };

  return (
    <>
      <Modal
        show={show}
        size={"lg"}
        animation={false}
        className={`animate__animated ${
          show ? "animate__zoomIn animate__fast" : "animate__fadeOut"
        }`}
        onHide={quitModal}
      >
        <Modal.Header closeButton className="custom-header">
          <Modal.Title className="custom-title" style={{ color: "#fff" }}>
            <h3 className="custom-title">Detalles de inscripto</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {!suc ? (
              <>
                <ProfilePersonalData
                  estado={itemSelected?.estado_id}
                  persona={itemSelected?.persona}
                  fecha_inscripcion={itemSelected?.created_at}
                  empresa={itemSelected?.empresa}
                  files={itemSelected?.persona?.files}
                  onChangeEstado={onChangeEstado}
                  carrera={itemSelected?.carrera}
                ></ProfilePersonalData>
                {rejectInput && (
                  <>
                    <div className="row mt-5 animate__animated animate__fadeIn">
                      <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                        <p>
                          <b>Motivo de Rechazo</b>
                        </p>
                        <EditorComponent 
                          handling={handleTinyEditor}
                          initialText={motivoRechazo}></EditorComponent>
                      </div>
                    </div>
                  </>
                )}
                {errorReject && (
                  <>
                    <p className="text-danger">{errorReject}</p>
                  </>
                )}
                <div className="row">
                  <div className="col-md-6 col-sm-12 col-12 text-center">
                    <button
                      disabled={loading}
                      type="button"
                      className="btn btn-secondary font-weight-bold px-9 py-4 my-3"
                      onClick={quitModal}
                    >
                      Cerrar
                    </button>
                  </div>
                  <div className="col-md-6 col-sm-12 col-12 text-center">
                    <button
                      className="btn btn-primary font-weight-bold px-9 py-4 my-3"
                      disabled={loading}
                      type="submit"
                      onClick={handleStatusSubmit}
                    >
                      OK
                      {loading && (
                        <span className="ml-3 spinner spinner-white"></span>
                      )}
                    </button>
                  </div>
                </div>
              </>
            ) : (
              <SuccessMessage
                title="la inscripción"
                opciones={true}
                optArray={[{ desc: "volver", func: quitModal }]}
              ></SuccessMessage>
            )}
          </>
        </Modal.Body>
      </Modal>
    </>
  );
}
