import React from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import { generateNewUserSchema, newUserInitialValues } from "../schemas/form_schemas";
import { Select } from "react-select-virtualized";
import { UserActions } from "../../../pages/Backoffice/usuarios/_hooks/usersHooks";
import SuccessMessage from "../../General/SuccessMessage";
import { Checkbox } from "../../../../_metronic/_partials/controls";

export default function ModalNewUser({
    show,
    changeModalState,
    roles,
    userSelected,
    update,
    add
}) {
    
  const [_roles] = React.useState(
    Array.from(new Set(roles.map((item) => item.name)))
  );

  const [successRequest, setSuccessRequest] = React.useState(false);
  const [errorRequest, setErrorRequest] = React.useState(false);
  const [loadingRequest, setLoadingRequest] = React.useState(false);

  const [canSelect, setCantSelect] = React.useState(userSelected?.role[0]?.name === 'user' ? true : false);
    const [disabledPassword, setDisabledPassword] = React.useState(false);
  React.useEffect(() => {
    if (userSelected) {
      form.setValues({
        name: userSelected ? userSelected.name : '',
        email: userSelected ? userSelected.email :'',
        password: '',
        c_password: '',
        password_required: userSelected ? 0 : 1,
        role: userSelected ? userSelected.role[0]?.name : '',
        role_description: userSelected ? userSelected.role[0]?.description : ''
      });
      setDisabledPassword(false);
    }
    else{
      setDisabledPassword(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelected]);

  const handlePasswordState = (e) => {
    if(e.target.checked){
      form.setFieldValue("password_required", 1);
    }
    else{
      form.setFieldValue("password_required", 0);
    }
    setDisabledPassword(e.target.checked);
  }

  const closeThisModal = () => {
    form.resetForm();
    setSuccessRequest(false);
    setErrorRequest(false);
    setLoadingRequest(false);
    changeModalState(false);
  }

  const handleRoleSelect = (e) => {
    if(e.target.value === "user"){
      setCantSelect(true);
      form.handleChange(e);
      form.setFieldValue("role_description", "User");
    }
    else{
      form.handleChange(e);
      setCantSelect(false);
    }
  }

  const resetUserForm = () => {
    setSuccessRequest(false);
    setLoadingRequest(false);
    form.resetForm();
  };


  const form = useFormik({
      initialValues: newUserInitialValues(userSelected),
      validationSchema: generateNewUserSchema(),
      onSubmit: async (values, {setStatus, resetForm}) => {
          try {
            setLoadingRequest(true);
            const { data: {user} } = await UserActions(userSelected, values);
            setLoadingRequest(false);
            setSuccessRequest(true);
            setStatus({
              success: true,
              error: false,
              message: "Usuario creado con éxito.",
            });
            if(userSelected){
              update(user)
            }
            else{
              add(user)
            }
          } catch (error) {
              setErrorRequest(error);
              setStatus({
                success: false,
                error: true,
                message: "Ha ocurrido un error al intentar realizar la petición.",
              });
              setLoadingRequest(false);
          }
      },
      onChange: (e) => {
          const { name, value } = e.target;
          form.setFieldValue(name, value);
        },
  })

  return (
    <>
      <Modal
        show={show}
        animation={false}
        size={"md"}
        className={`animate__animated ${
          show ? "animate__zoomIn animate__fast" : "animate__fadeOut"
        }`}
        onHide={() => {}}
      >
        <Modal.Header className="custom-header" closeButton>
          <Modal.Title className="custom-title" style={{ color: "#fff" }}>
            <h3 className="custom-title">{userSelected ? 'Editar Usuario' : 'Nuevo Usuario'}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="row">
                <div className="col-xl-12 col-md-12 col-sm-12 col-12 my-5">
                    <form onSubmit={form.handleSubmit} action="" className="form fv-plugins-bootstrap fv-plugins-framework">
                      {
                        console.log(form.status)
                      }
                      {form.status && (
                        <div
                          className={`border-10 alert alert-custom  ${
                            form.status.success
                              ? "alert-light-success"
                              : form.status.error
                              ? "alert-light-danger"
                              : ""
                          } alert-dismissible`}
                        >
                          <div className="alert-text font-weight-bold">
                            {form.status.message}
                          </div>
                        </div>
                      )}
                      {
                        !successRequest ? 
                        (
                            <>
                              <div className="form-group">
                            <label className="form-label" htmlFor="">Nombre</label>
                            <input type="text" className="form-control"
                                name="name"
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                value={form.values.name}
                            />
                            {form.touched.name && form.errors.name ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {form.errors.name}
                          </div>
                        </div>
                      ) : null}
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="">Email</label>
                            <input type="text" className="form-control"
                                name="email"
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                value={form.values.email}
                            />
                            {form.touched.email && form.errors.email ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {form.errors.email}
                          </div>
                        </div>
                      ) : null}
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="">Rol</label>
                            <Select
                              isSearchable={false}
                              placeholder="Elija un rol..."
                              label="Elija un rol..."
                              name="role"
                              id="role"
                              options={_roles?.map((item) => ({
                                value: item,
                                label: item,
                              }))}
                              onChange={(selectedOption) => {
                                const e = {
                                  target: {
                                    name: "role",
                                    value: selectedOption ? selectedOption.value : "",
                                  },
                                };
                                handleRoleSelect(e);
                              }}
                              defaultValue={
                                userSelected
                            ? (() => {
                                const option = _roles?.find(
                                  (item) => item === userSelected.role[0]?.name
                                );
                                if (option) {
                                  return {
                                    value: option,
                                    label: option,
                                  };
                                }
                                else{
                                  return {
                                    value: '',
                                    label: ''
                                  }
                                }
                              })()
                            : null
                              }/>
                            {form.touched.role && form.errors.role ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {form.errors.role}
                          </div>
                        </div>
                      ) : null}
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="">Grupo</label>
                            <Select
                              isSearchable={false}
                              placeholder="Elija un grupo..."
                              label="Elija un grupo..."
                              name="role_description"
                              id="role_description"
                              options={roles?.filter(item => item.description != "User").map((item) => ({
                                value: item.description,
                                label: item.description,
                              }))}
                              onChange={(selectedOption) => {
                                const e = {
                                  target: {
                                    name: "role_description",
                                    value: selectedOption ? selectedOption.value : "",
                                  },
                                };
                                form.handleChange(e);
                              }}
                              defaultValue={
                                userSelected ?
                                  (() => {
                                    const option = roles?.find( item => item.description === userSelected.role[0]?.description)
                                    if(option){
                                      return {
                                        value: option.description,
                                        label: option.description
                                      }
                                    }
                                    else{
                                      return {
                                        value: '',
                                        label: ''
                                      }
                                    }
                                  })()
                                :
                                null
                              }
                              isDisabled={canSelect}
                            />
                        {form.touched.role && form.errors.role ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {form.errors.role}
                          </div>
                        </div>
                      ) : null}
                        </div>
                        {
                          userSelected &&
                          (
                            <Checkbox onChange={handlePasswordState}>
                              <label className="mr-5">Habilitar cambio de contraseña</label>{" "}
                            </Checkbox>
                          )
                        }
                        <div className={`${disabledPassword ? 'd-block' : 'd-none'}`}>
                        <div className="form-group">
                            <label className="form-label" htmlFor="">Contraseña</label>
                            <input type="password" className="form-control"
                                name="password"
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                />
                            {form.touched.password && form.errors.password ? (
                              <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {form.errors.password}
                          </div>
                        </div>
                      ) : null}
                        </div>
                        <div className="form-group">
                            <label className="form-label" htmlFor="">Confirmar contraseña</label>
                            <input type="password" className="form-control"
                                name="c_password"
                                onBlur={form.handleBlur}
                                onChange={form.handleChange}
                                />
                            {form.touched.password && form.errors.password ? (
                              <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {form.errors.password}
                          </div>
                        </div>
                      ) : null}
                        </div>
                      </div>
                        <div className="row justify-content-center align-items-center">
                    <div className="col-md-6 col-sm-12 col-12 text-center">
                      <button
                        disabled={loadingRequest}
                        type="button"
                        className="btn btn-secondary font-weight-bold px-9 py-4 my-3"
                        onClick={() => closeThisModal(false)}
                      >
                        Cerrar
                      </button>
                    </div>
                    <div className="col-md-6 col-sm-12 col-12 text-center">
                      <button
                        className="btn btn-primary font-weight-bold px-9 py-4 my-3"
                        disabled={loadingRequest}
                        type="submit"
                      >
                        OK
                        {loadingRequest && (
                          <span className="ml-3 spinner spinner-white"></span>
                        )}
                      </button>
                    </div>
                  </div>
                            
                            </>
                        )
                        :
                        (
                          <>
                        <SuccessMessage
                            titulo="Operación éxitosa"
                            othertitle={true}
                            opciones={true}
                            optArray={[
                              { desc: "Cerrar", func: closeThisModal },
                              { desc: "Cargar Otro", func: resetUserForm },
                            ]}
                          ></SuccessMessage>
                          </>
                        )
                      }
                        
                    </form>
                </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
